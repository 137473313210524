import request from '@/api/index.js'
// 获取泛团list
export function getTeamsInfo (params = {}) {
  return request({
    url: '/api/teams',
    method: 'get',
    params
  })
}
// 修改泛团备注
export function sendChstatus (data) {
  return request({
    url: '/api/team/chstatus',
    method: 'post',
    data
  })
}
// 获取工会的参与项目
export function projectDone (id) {
  return request({
    url: `/api/team/project/done/${id}`,
    method: 'get'
  })
}
/**
 * 获取公告列表
*/
export function getAfficheList (params = {}) {
  return request({
    url: '/api/team/affiche',
    method: 'get',
    params
  })
}

/**
  * 获取公告详情
*/
export function getAfficheDetail (params) {
  return request({
    url: '/api/team/affiche/txt',
    method: 'get',
    params
  })
}
/**
  * 发布公告
*/

export function addAffiche (data) {
  return request({
    url: '/api/team/affiche/new',
    method: 'post',
    data
  })
}

/**
  * 编辑公告
*/
export function editAffiche (data) {
  return request({
    url: '/api/team/affiche/update',
    method: 'post',
    data
  })
}
/**
  * 下线公告
*/
export function offlineAffiche (data) {
  return request({
    url: '/api/team/affiche/off',
    method: 'post',
    data
  })
}
